// Layout
const dashboardLayout = () => import('@/layouts/HospitalLayout/DashboardLayout/dashboardLayout.vue')

const processing = () => import('./views/index')
/* const addProduct = () => import('./views/addProduct') */

// start Routes
export default [
  {
    path: '/hospital/processing',
    component: dashboardLayout,
    children: [{
      path: '',
      name: 'hospitalProcessing',
      meta: {
        name: 'processing',
        parentName: 'processing',
        userType: '',
        permission: '',
        role: 'veterinary.clinic_owner'
      },
      component: processing
    }]
  }
]
