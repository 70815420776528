// Layout
const dashboardLayout = () => import('@/layouts/HospitalLayout/DashboardLayout/dashboardLayout.vue')

const doctors = () => import('./views/index')

const addDoctor = () => import('./views/addDoctor')

// start Routes
export default [
  {
    path: '/hospital/doctors',
    component: dashboardLayout,
    children: [{
      path: '',
      name: 'hospitalDoctors',
      meta: {
        name: 'hospitalDoctors',
        parentName: 'marketplace',
        userType: '',
        permission: '',
        role: 'veterinary.clinic_owner'
      },
      component: doctors
    }, {
      path: 'add-doctor',
      name: 'hospitalAddDoctor',
      meta: {
        name: 'hospitalAddDoctor',
        parentName: 'marketplace',
        userType: '',
        permission: '',
        role: 'veterinary.clinic_owner'
      },
      component: addDoctor
    }]
  }
]
