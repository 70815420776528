import Vue from 'vue'
import VueRouter from 'vue-router'
import auth from '@/modules/auth/auth.routes'
import authMixin from '@/mixins/auth-permission.js'
// doctor routes
import dashboardRoutes from '@/modules/doctorDashboard/dashboard/dashboard.routes'
import settingsRoutes from '@/modules/doctorDashboard/settings/settings.routes'
import booksRoutes from '@/modules/doctorDashboard/books/books.routes'
import financialOperationsRoutes from '@/modules/doctorDashboard/financialOperations/financialOperations.routes'
import dashboardDoctorProcessing from '@/modules/doctorDashboard/processing/processing.routes'
import doctorSupport from '@/modules/doctorDashboard/support/support.routes'

// hospital routes
import hospitalDashboardRoutes from '@/modules/hospitalDashboard/dashboard/dashboard.routes'
import hospitalSettingsRoutes from '@/modules/hospitalDashboard/settings/settings.routes'
import hospitalBooksRoutes from '@/modules/hospitalDashboard/books/books.routes'
import hospitalFinancialOperationsRoutes from '@/modules/hospitalDashboard/financialOperations/financialOperations.routes'
import hospitalDoctors from '@/modules/hospitalDashboard/doctors/doctors.routes'
import dashboardHospitalProcessing from '@/modules/hospitalDashboard/processing/processing.routes'
import hospitalSupport from '@/modules/hospitalDashboard/support/support.routes'

/* Extra Pages */
const ErrorPage = () => import('../views/Pages/ErrorPage')

Vue.use(VueRouter)

const routes = [
  // doctor ************
  ...dashboardRoutes,
  ...settingsRoutes,
  ...booksRoutes,
  ...financialOperationsRoutes,
  ...dashboardDoctorProcessing,
  ...doctorSupport,
  // hospital ***********
  ...hospitalDashboardRoutes,
  ...hospitalSettingsRoutes,
  ...hospitalBooksRoutes,
  ...hospitalFinancialOperationsRoutes,
  ...hospitalDoctors,
  ...dashboardHospitalProcessing,
  ...hospitalSupport,
  // auto
  ...auth,
  {
    path: '*',
    name: 'errorPage',
    meta: { name: 'dashboard', userType: 'both' },
    component: ErrorPage
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})
router.beforeEach((to, from, next) => {
  if (localStorage.getItem('my-roles') && authMixin.methods.hasPer(to.meta.role)) {
    next()
  } else {
    if (to.meta.role === 'both') {
      next()
    } else {
      next({ name: 'login' })
    }
  }
})
/* router.beforeEach((to, from, next) => {
    next(vm => {
      if (!vm.checkIfLogin()) {
        next({ name: 'login' })
      } else next()
    })
  if (to.meta.userType === 'both') {
    next()
  } else if (securityFunctions.methods.isAdmin(to.meta.userType)) {
    if (securityFunctions.methods.hasPer(to.meta.permission)) {
      next()
    } else {
      debugger
      next({ name: 'errorPage' })
    }
  } else {
    if (securityFunctions.methods.hasServiceType(to.meta.serviceTypes) || to.meta.serviceTypes === 'all') {
      next()
    } else {
      debugger
      next({ name: 'errorPage' })
    }
  }
}) */
export default router
